import React from "react";
import { MenuItemLink, Text } from "@envato/design-system/components";

type MenuItemProps = {
  link: string;
  text: string;
  onClick?: () => void;
};

export const HeaderMenuItem: React.FC<MenuItemProps> = ({
  link,
  text,
  onClick,
}) => {
  return (
    <MenuItemLink to={link} onClick={onClick} role="option">
      <Text variant={{ 200: "label-large", default: "body-small" }}>
        {text}
      </Text>
    </MenuItemLink>
  );
};
