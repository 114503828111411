import logoEnvatoWhite from "./../assets/images/logos/logo-envato-white.svg";
import logoPlaceit from "./../assets/images/logos/logo-placeit-white.svg";

const clientLogo = (appId?: string) => {
  const logoKey = appId;

  switch (logoKey) {
    case "market":
      return logoEnvatoWhite;
    case "placeit":
      return logoPlaceit;
    default:
      return logoEnvatoWhite;
  }
};

export default clientLogo;
