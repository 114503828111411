import { FormatDateOptions } from "@formatjs/intl";

export const dateTimeFormat: FormatDateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: "UTC",
  hour: "numeric",
  minute: "numeric",
};

const AvailableLanguageCodes: Array<LanguageCode> = [
  "en",
  "es",
  "pt-BR",
  "fr",
  "ru",
  "de",
];

export const localisedPath = (location: Location, locale: LanguageCode) => {
  const stripLocaleFrom = (currentPath: string) => {
    return AvailableLanguageCodes.reduce(
      (acc, current) => acc.replace(`/${current}/`, "/"),
      currentPath,
    );
  };

  return (
    `/${locale}${stripLocaleFrom(location.pathname)}`.replace("/en", "") +
    location.search
  );
};
