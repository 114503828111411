import React from "react";

import { Box, ButtonLink, Text } from "@envato/design-system/components";

type HeaderButtonLinkProps = {
  link: string;
  label: string;
};
export const HeaderButtonLink: React.FC<HeaderButtonLinkProps> = ({
  link,
  label,
}) => {
  return (
    <ButtonLink to={link} variant="naked" role="link">
      <Box alignItems="center" borderRadius="subtle" padding="2x">
        <Text variant="body-small" color="primary">
          {label}
        </Text>
      </Box>
    </ButtonLink>
  );
};
