import React from "react";

import { Box, DropdownMenu } from "@envato/design-system/components";

import { HeaderMenuItem } from "./MenuItems/HeaderMenuItem";
import { useLocalisedPath, UserData, useTranslation } from "@envato/sso-common";
import { useAuthTracking } from "@envato/sso-analytics";
import { AccountMenuButtonTrigger } from "./AccountMenuButtonTrigger";
import { MenuItems } from "./MenuItems";

export type AccountMenuProps = {
  currentUser: UserData;
};

export const AccountMenu: React.FC<AccountMenuProps> = ({ currentUser }) => {
  const t = useTranslation();
  const handleAuthEvent = useAuthTracking();

  return (
    <Box justifyContent="flex-end">
      <DropdownMenu
        trigger={
          <AccountMenuButtonTrigger>
            {currentUser.displayName}
          </AccountMenuButtonTrigger>
        }
        colorScheme="dark"
        placement="bottom-end"
      >
        <MenuItems currentUser={currentUser} />
        <HeaderMenuItem
          link={useLocalisedPath("signOut")}
          text={t("topbar.signOut")}
          onClick={() =>
            handleAuthEvent({ type: "auth", eventName: "sign_out" })
          }
        />
      </DropdownMenu>
    </Box>
  );
};
