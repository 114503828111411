import React from "react";

import { Box, CustomModalBase } from "@envato/design-system/components";
import { UserData } from "@envato/sso-common";
import { AccountMenuButtonTrigger } from "../AccountMenu/AccountMenuButtonTrigger";
import NavigationDrawerFooter from "./NavigationDrawerFooter";
import { MenuItems } from "../AccountMenu/MenuItems";
import { Logo } from "../../Logo";

type NavigationDrawerProps = {
  currentUser: UserData;
  homeLink: string;
  logo: string;
};

export const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  currentUser,
  homeLink,
  logo,
}) => {
  return (
    <CustomModalBase
      alignItems="flex-start"
      dismissible
      insetTop="none"
      insetBottom="none"
      insetX="none"
      justifyContent="flex-end"
      maxWidth="viewport"
      transitionDuration="short"
      trigger={<AccountMenuButtonTrigger icon="menu-hamburger" />}
    >
      {({ Header, status }) => (
        <>
          <Box
            backgroundColor="primary"
            colorScheme="dark"
            dangerouslySetStyle={{ maxWidth: "500px" }}
            width="viewport"
            height="viewport"
            containerType="inline-size"
            display="flex"
            flexDirection="column"
            transform={status === "open" ? undefined : "right-full"}
            transitionDuration="short"
            transitionProperty="transform"
            transitionTimingFunction="ease-in-out"
          >
            <Header>
              <Box
                display="flex"
                paddingX="3x"
                height="full"
                alignItems="center"
              >
                <Logo linkTo={homeLink} logoSrc={logo} />
              </Box>
            </Header>
            <MenuItems currentUser={currentUser} />
            <NavigationDrawerFooter />
          </Box>
        </>
      )}
    </CustomModalBase>
  );
};
