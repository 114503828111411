import React from "react";
import { Box, ButtonLink } from "@envato/design-system/components";
import { useLocalisedPath, useTranslation } from "@envato/sso-common";
import { useAuthTracking } from "@envato/sso-analytics";

const NavigationDrawerFooter = () => {
  const t = useTranslation();
  const signOutLink = useLocalisedPath("signOut");
  const handleAuthEvent = useAuthTracking();

  return (
    <Box width="full" paddingX="4x" paddingY="3x" alignItems="center">
      <ButtonLink
        variant="tertiary"
        onClick={() => {
          handleAuthEvent({ type: "auth", eventName: "sign_out" });
        }}
        to={signOutLink}
        width="full"
      >
        {t(`topbar.signOut`)}
      </ButtonLink>
    </Box>
  );
};

export default NavigationDrawerFooter;
