import { useLocalisedPath, UserData, useTranslation } from "@envato/sso-common";
import { Box } from "@envato/design-system/components";
import React from "react";
import { HeaderMenuItem } from "./HeaderMenuItem";

const menuItemMinWidth = { minWidth: "200px" };

type MenuItemsProps = {
  currentUser: UserData;
};

export const MenuItems: React.FC<MenuItemsProps> = ({ currentUser }) => {
  const t = useTranslation();

  return (
    <Box flexGrow="1" dangerouslySetStyle={menuItemMinWidth}>
      {currentUser.canViewSidekiq && (
        <HeaderMenuItem
          key="topbarSidekiqButton"
          link={useLocalisedPath("sidekiqWeb")}
          text={t("topbar.sidekiq")}
        />
      )}
      {currentUser.admin && (
        <HeaderMenuItem
          link={useLocalisedPath("admin")}
          text={t("topbar.admin")}
        />
      )}
    </Box>
  );
};
