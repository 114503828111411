import React, { useCallback, useState } from "react";
import styled from "styled-components";

import * as Analytics from "@envato/sso-analytics";
import { Stack, Box, Text, Link, ContextButton } from "@envato/eds";
import { useLanguageCode } from "@envato/sso-common";

import toggleImage from "../../assets/images/arrow-up.svg";
import { localisedPath } from "../../utils/i18n";
import { Icon, Text as NewText } from "@envato/design-system/components";

const Container = styled.div`
  position: relative;
`;

const ToggleButton = styled(ContextButton)`
  cursor: pointer;
  border: 0;
  background: none;
  appearance: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 8px;
  padding-bottom: 8px;

  display: flex;
  align-items: center;

  > * {
    margin-right: ${(props) => props.theme.spacing2x};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ToggleImage = styled.img.attrs({
  src: toggleImage,
})<{ isOpen: boolean }>`
  transition: all ${(props) => props.theme.transitionDurationShort}
    ${(props) => props.theme.transitionEasingLinear};
  transform: rotate(180deg);
  ${(props) => props.isOpen && "transform: rotate(0deg);"}
`;

const locales: { [key: string]: string } = {
  de: "Deutsch",
  en: "English",
  es: "Español",
  fr: "Français",
  "pt-BR": "Português brasileiro",
  ru: "Pусский",
};

const Mask = styled.div`
  background: transparent;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const LocaleOptionsContainer = styled(Box).attrs({
  padding: "3x",
})`
  position: absolute;
  left: 0;
  margin: 0;
  bottom: ${(props) => props.theme.spacing6x};
  border-radius: ${(props) => props.theme.radiusBase};
  background-color: ${(props) => props.theme.bgPrimary};
  box-shadow: ${(props) => props.theme.shadowLevel1};

  @media (min-width: ${(props) => props.theme.breakMedium}) {
    left: initial;
    right: 0;
  }
`;

const LocaleOptionsList = styled(Stack).attrs({
  spacing: "3x",
  as: "div",
})`
  list-style: none;
  padding: 0;
`;

const LocaleOptionsItem = styled(Text).attrs({
  forwardedAs: "li",
  variant: "body-large",
})``;

const LocaleOptions = () => (
  <LocaleOptionsContainer>
    <LocaleOptionsList>
      {Object.keys(locales).map((selected) => (
        <LocaleOptionsItem key={selected}>
          <Link to={localisedPath(window.location, selected as LanguageCode)}>
            {locales[selected]}
          </Link>
        </LocaleOptionsItem>
      ))}
    </LocaleOptionsList>
  </LocaleOptionsContainer>
);

const View = () => {
  const [showOptions, setShowOptions] = useState(false);
  const currentLocale = useLanguageCode();

  const toggleOptions = useCallback(() => {
    setShowOptions(!showOptions);
  }, [showOptions]);

  return (
    <Container>
      {showOptions && <Mask onClick={toggleOptions} />}
      {showOptions && <LocaleOptions />}
      <Analytics.Element
        trackingId="LocaleSwitcher"
        trackingLabel={showOptions ? "hide" : "show"}
      >
        <ToggleButton onClick={toggleOptions}>
          <Icon name="globe"></Icon>
          <NewText variant="label-large">{locales[currentLocale]}</NewText>
          <ToggleImage isOpen={showOptions} />
        </ToggleButton>
      </Analytics.Element>
    </Container>
  );
};

export default View;
