import React from "react";

import { NotForPrint } from "@envato/eds";
import {
  Box,
  Columns,
  PageBlock,
  Stack,
} from "@envato/design-system/components";
import { InlineLinkGroup } from "./InlineLinkGroup";
import { SocialLinks } from "./SocialLinks";
import { Copyright } from "./Copyright";
import LocaleSwitcher from "../LocaleSwitcher";

const Footer: React.FC = () => {
  return (
    <NotForPrint>
      <Box
        colorScheme="light"
        display="flex"
        containerType="inline-size"
        backgroundColor="secondary"
      >
        <PageBlock
          backgroundColor="secondary"
          maxWidth="breakpoint-2k-wide"
          paddingY="6x"
        >
          <Stack spacing="2x">
            <InlineLinkGroup />
            <Columns
              justifyContent="space-between"
              collapseBelow={600}
              collapsedSpacing="2x"
              alignItems={{ default: "flex-start", 600: "center" }}
            >
              <SocialLinks />
              <LocaleSwitcher />
            </Columns>
            <Copyright />
          </Stack>
        </PageBlock>
      </Box>
    </NotForPrint>
  );
};

export default Footer;
