import { Text } from "@envato/design-system/components";
import { useTranslation } from "@envato/sso-common";
import React from "react";

export const Copyright: React.FC = () => {
  const t = useTranslation();

  return (
    <Text color="secondary" variant="body-small">
      {t("footer.copyright")}
    </Text>
  );
};
