import React from "react";
import { Link } from "@envato/design-system/components";

export type LogoProps = {
  width?: number;
  height?: number;
  logoSrc: string;
  linkTo: string;
};

export const Logo = ({ width, height = 24, logoSrc, linkTo }: LogoProps) => {
  return (
    <Link display="flex" to={linkTo}>
      <img src={logoSrc} height={height} width={width} alt="Envato" />
    </Link>
  );
};
