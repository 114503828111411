import styled from "styled-components";

export const HideBelow1300 = styled.div`
  display: block;

  @media (min-width: 1300px) {
    display: none;
  }
`;

export const HideAbove1300 = styled.div`
  display: none;

  @media (min-width: 1300px) {
    display: block;
  }
`;
