import {
  Bleed,
  Box,
  Icon,
  Inline,
  Link,
} from "@envato/design-system/components";
import { useExternalLink } from "@envato/sso-common";
import React from "react";

export const SocialLinks: React.FC = () => {
  const socialLinksData = [
    {
      name: "Instagram",
      url: useExternalLink("instagramAccount"),
      SocialIcon: <Icon name="instagram" />,
    },
    {
      name: "TikTok",
      url: useExternalLink("tiktokAccount"),
      SocialIcon: <Icon name="tik-tok" />,
    },
    {
      name: "Facebook",
      url: useExternalLink("facebookAccount"),
      SocialIcon: <Icon name="facebook-square" />,
    },
    {
      name: "YouTube",
      url: useExternalLink("youtubeAccount"),
      SocialIcon: <Icon name="youtube-outlined" />,
    },
    {
      name: "Threads",
      url: useExternalLink("threadsAccount"),
      SocialIcon: <Icon name="threads" />,
    },
    {
      name: "Pinterest",
      url: useExternalLink("pinterestAccount"),
      SocialIcon: <Icon name="pinterest" />,
    },
    {
      name: "Twitter",
      url: useExternalLink("twitterAccount"),
      SocialIcon: <Icon name="twitter-x" />,
    },
  ];

  return (
    <Bleed left="2x">
      <Inline>
        {socialLinksData.map(({ name, url, SocialIcon }) => (
          <Link to={url} key={name}>
            <Box
              width="icon-5x"
              height="icon-5x"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {SocialIcon}
            </Box>
          </Link>
        ))}
      </Inline>
    </Bleed>
  );
};
