import { Inline, Text, TextLink } from "@envato/design-system/components";
import { useExternalLink, useTranslation } from "@envato/sso-common";
import React from "react";

const FooterLink = ({ to, text }: { to: string; text: string }) => (
  <TextLink variant="naked" to={to}>
    <Text variant="body-large">{text}</Text>
  </TextLink>
);

const FooterButtonLink = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => (
  <TextLink variant="naked" to="#" onClick={onClick}>
    <Text variant="body-large">{text}</Text>
  </TextLink>
);

export const InlineLinkGroup: React.FC = () => {
  const t = useTranslation();

  return (
    <Inline dividers={true} spacing="3x" collapsedSpacing="2x">
      <FooterLink
        key="about"
        to={useExternalLink("envatoAbout")}
        text={t("footer.about")}
      />
      <FooterLink
        key="planPricing"
        to={useExternalLink("elementsPricing")}
        text={t("footer.planPricing")}
      />
      <FooterLink
        key="license"
        to={useExternalLink("elementsLicenseTerms")}
        text={t("footer.license")}
      />
      <FooterLink
        key="terms"
        to={useExternalLink("elementsTerms")}
        text={t("footer.terms")}
      />
      <FooterLink
        key="privacy"
        to={useExternalLink("envatoPrivacyPolicy")}
        text={t("footer.privacy")}
      />
      <FooterLink
        key="cookies"
        to={useExternalLink("envatoCookies")}
        text={t("footer.cookies")}
      />
      <FooterLink
        key="personalInformation"
        to={useExternalLink("envatoPersonalInformation")}
        text={t("footer.personalInformation")}
      />
      <FooterButtonLink
        key="cookieSettings"
        text={t("footer.cookieSettings")}
        onClick={() => {
          if (window.Cookiebot) {
            window.addEventListener("CookiebotOnLoad", () =>
              window.location.reload(),
            );
            window.Cookiebot.renew();
          }
          return false;
        }}
      />
    </Inline>
  );
};
