import React, { forwardRef } from "react";

import {
  Bleed,
  Box,
  Columns,
  CustomButtonBase,
  Icon,
  Text,
} from "@envato/design-system/components";

type AccountMenuTriggerProps = {
  icon?: "menu-hamburger";
  children?: string;
};

const fontVariant = "body-small";

export const AccountMenuButtonTrigger = forwardRef<
  HTMLButtonElement,
  AccountMenuTriggerProps
>(({ children, icon, ...restProps }, ref) => {
  const boxDisplay = icon ? "block" : "flex";
  const boxHeight = icon ? "auto" : "icon-2x";
  const boxPaddingRight = icon ? "none" : "3x";
  const menuIcon = icon || "chevron-down";
  const iconSize = icon ? "2x" : "1x";

  return (
    <CustomButtonBase
      {...restProps}
      backgroundColor="interactive-tertiary"
      borderRadius="subtle"
      color={{
        default: "primary",
        hover: "secondary",
        active: "secondary",
        focus: "secondary",
        focusVisible: "secondary",
      }}
      fontFamily={fontVariant}
      fontSize={fontVariant}
      fontWeight={fontVariant}
      letterSpacing={fontVariant}
      lineHeight={fontVariant}
      paddingY="1x"
      paddingX="none"
      ref={ref}
      role="combobox"
      aria-label="Account Menu"
    >
      <Bleed right="3x">
        <Box paddingX="3x" paddingY="2x">
          <Columns alignItems="center" spacing="1x">
            {children && <Text variant="body-small">{children}</Text>}
            <Box
              transitionDuration="short"
              transitionProperty="transform"
              transitionTimingFunction="ease-in-out"
              display={boxDisplay}
              height={boxHeight}
              paddingRight={boxPaddingRight}
              alignItems="center"
            >
              <Icon name={menuIcon} size={iconSize} />
            </Box>
          </Columns>
        </Box>
      </Bleed>
    </CustomButtonBase>
  );
});

AccountMenuButtonTrigger.displayName = "AccountMenuTrigger";
